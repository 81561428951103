<template>
  <div class="row">
    <!-- <div class="col-md-3">
        <tab-nav :pills="true" id="tab" class="nav nav-pills basic-info-items list-inline d-block p-0 m-0 showHoriZon" >
          <tab-nav-items :active="true" id="pills-about-info" href="#about-info" ariaControls="pills-about-info" role="tab" :ariaSelected="true" title="Details" />
          <tab-nav-items :active="false" id="pills-contact-info" href="#contact-info" ariaControls="pills-contact-info" role="tab" :ariaSelected="true" title="Contact" />
        </tab-nav>
      </div> -->
    <div class="col-md-12">
      <div class="tab-content">
        <tab-content-item :active="true" id="about-info" aria-labelled-by="pills-about-info">
          <div class="iq-card">
            <div class="iq-card-body">
              <h4>
                About
              </h4>
              <hr />
              <!-- <p class="mb-2">{{propOrganisationObj.org_name}}</p> -->
              <p class="mb-2">
                <!-- <span  v-if="propOrganisationObj.org_founding_year">Founded in {{propOrganisationObj.org_founding_year}}
                  </span> -->
                <span :class="propShowReadMore && showReadMore ? 'threeLineOnly':''" v-html="propOrganisationObj.org_desc"></span>
                <span class="pl-2 pointer showMore" v-if="propShowReadMore && showReadMore">
                  <u><a @click="emitOpenAboutTab()">Show more</a></u>
                </span>
              </p>
            </div>
          </div>

          <!-- Show Below Info to Univ User Only  -->
          <div class="iq-card" v-if="userData.user_role === 'USERROLE11115'">
            <div class="iq-card-body">
              <h4>
                Tution Fees
              </h4>
              <hr />
              <div class="row">
                <div class="col-6">
                  <h6 class="mt-1 mb-1">
                    Average Tution Minimum
                  </h6>
                  <p class="mb-0">
                    {{propOrganisationObj.org_currency}}&nbsp;{{propOrganisationObj.org_avg_tuition_min}}
                  </p>
                </div>
                <div class="col-6">
                  <h6 class="mt-1 mb-1">
                    Average Tution Maximum
                  </h6>
                  <p class="mb-0">
                    {{propOrganisationObj.org_currency}}&nbsp;{{propOrganisationObj.org_avg_tuition_max}}
                  </p>
                </div>

                <div class="col-6">
                  <h6 class="mt-1 mb-1">
                    Scholarship Min
                  </h6>
                  <p class="mb-0">
                    {{propOrganisationObj.org_currency}}&nbsp;{{propOrganisationObj.org_scholarship_min}}
                  </p>
                </div>
                <div class="col-4">
                  <h6 class="mt-1 mb-1">
                    Scholarship Max
                  </h6>
                  <p class="mb-0">
                    {{propOrganisationObj.org_currency}}&nbsp;{{propOrganisationObj.org_scholarship_max}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="propOrganisationObj.org_website">
            <h4 class="mt-3">
              Website
            </h4>
            <hr>
            <div class="row">
              <div class="col-12">
                <p class="mb-0">
                  {{propOrganisationObj.org_website}}
                </p>
              </div>
            </div>
          </div>

          <div class="iq-card" v-if="propOrganisationObj.org_admission_requirement && !propShowReadMore">
            <div class="iq-card-body">
              <h4>
                Admission Requirement
              </h4>
              <hr />
              <div class="row">
                <hr>
                <div class="col-md-12">
                  <p class="htmlComing vhtmlTag vueHtmlParent" v-html="propOrganisationObj.org_admission_requirement">
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="iq-card" v-if="propOrganisationObj.org_top_alums && !propShowReadMore">
            <div class="iq-card-body">
              <h4>
                Top Alums
              </h4>
              <hr />
              <div class="row">
                <hr>
                <div class="col-md-12">
                  <p class="htmlComing vhtmlTag vueHtmlParent" v-html="propOrganisationObj.org_top_alums">
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="iq-card" v-if="propOrganisationObj.org_clubs && !propShowReadMore">
            <div class="iq-card-body">
              <h4>
                Societies & clubs
              </h4>
              <hr />
              <div class="row">
                <hr>
                <div class="col-md-12">
                  <p class="htmlComing vhtmlTag vueHtmlParent" v-html="propOrganisationObj.org_clubs">
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="iq-card" v-if="propOrganisationObj.org_major_industries_around && !propShowReadMore">
            <div class="iq-card-body">
              <h4>
                Major Industries Around
              </h4>
              <hr />
              <div class="row">
                <hr>
                <div class="col-md-12">
                  <p class="htmlComing vhtmlTag vueHtmlParent" v-html="propOrganisationObj.org_major_industries_around">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="contact-info" aria-labelled-by="pills-contact-info">
          <h4>
            Contact Information
          </h4>
          <hr>
          <div class="row">
            <div class="col-3">
              <h6>
                Email
              </h6>
            </div>
            <div class="col-9">
              <p class="mb-0">
                {{propOrganisationObj.org_website_email}}
              </p>
            </div>
            <div class="col-3">
              <h6>
                Mobile
              </h6>
            </div>
            <div class="col-9">
              <p class="mb-0">
                {{propOrganisationObj.org_mobile}}
              </p>
            </div>
            <div class="col-3">
              <h6>
                Address
              </h6>
            </div>
            <div class="col-9">
              <p class="mb-0">
                {{propOrganisationObj.org_city}}, {{propOrganisationObj.country_name}}
              </p>
            </div>
          </div>
        </tab-content-item>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .showMore {
    position: absolute;
    margin-top: -26px;
    right: 25px;
    background: white;
  }
</style>
<script>
export default {
  name: "About",
  props: {
    propOrganisationObj: {
      type: Object,
      default: function () {
        return {
          org_name: "",
          org_desc: "",
          org_college_basis_app: "",
          org_city: "",
          org_country: "",
          org_website: "",
          org_founding_year: "",
          org_deadline: "",
          org_intake_count: "",
          org_intake_type: "",
          org_currency: "",
          org_avg_tuition_min: "",
          org_avg_tuition_max: "",
          org_scholarship_min: "",
          org_scholarship_max: ""
        }
      }
    },
    propShowReadMore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  data () {
    return {
      description: "",
      showReadMore: false
    }
  },
  mounted () {
    if (this.propShowReadMore && this.propOrganisationObj.org_desc && this.propOrganisationObj.org_desc.length) {
      this.showReadMore = true
    }
  },
  methods: {
    /**
     * emitOpenAboutTab
     */
    emitOpenAboutTab () {
      this.$emit("emitOpenAboutTab", true)
    }
  }
}
</script>
