<template>
  <b-container fluid class="p-0">
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h5 class="card-title">
              {{cvCardTitle}}
            </h5>
          </template>
          <template>
            <div class="row p-0">
              <div class="col-12 boxes">
                <div class="card text-white bg-primary iq-mb-3 p-3">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div>
                        Total Users
                        <h6 class="text-white user-card">{{userCount}}</h6>
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template>
            <b-row class="w-100 mb-3">
              <b-col class="col-12 col-sm-5 col-md-3 col-lg-3 mb-2">
                <b-form-input class="search_box"
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="userObjList" csv-title="school_student_report" v-if="userObjList && userObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <select
                  v-model="cvClassFilter"
                  type="text"
                  class="form-control"
                  id="validation_degree"
                  @change="userListSetClassFilter">
                  <option v-for="(Class, index) of Class" :key="index+'OrgType'">{{ Class }}</option>
                </select>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="userObjList && userObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="userObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(user_name)="data">
                    <div>
                      <p v-html="data.item.user_name" style="word-wrap: break-word" class="mb-0"></p>
                      <i><small>id: <span v-html="data.item.user_id"></span></small></i><br>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm pointer" size="sm"><i class="fa-solid fa-eye fa-sm" @click="goToUserProfile(data.item)" title="View Student"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm pointer" size="sm" v-if="data.item.gpath_report && cvOrgType == 'SCH'" @click="openGPathsReport(data.item.gpath_report)" title="View GPaths Report"><i class="fa fa-map-signs" aria-hidden="true"></i></b-button>
                    </div>
                  </template>
                  <template v-slot:cell(user_mobile)="data">
                    <p v-html="data.item.user_mobile" style="word-wrap: break-word"></p>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{ data.item.created_on | dateFormatDDMMYYYY }}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style scoped>
.dateRange {
  float: right;
  }
  .iq-card .iq-card-header{
    padding: 0px!important;
  }
  .user-card{
    font-size: 22px !important;
    font-weight: 600 !important;
  }
.boxes .card {
  width: 160px;
  display: inline-flex;
  margin-right: 20px;
}
.boxes .card .card-body{
  padding: 0px;
}
</style>
<script>
import { User } from "../../../FackApi/api/user.js"
import { analyticsUser } from "../../../FackApi/api/analytics/analytics_user"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"
import Class from "../../../FackApi/json/Class.json"

export default {
  name: "UserList",
  props: {
    propOrgId: {
      type: String,
      default: null
    }
  },
  components: {
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      cvModuleFile: "user_school_student",
      cvModulePrefix: "USER",
      cvCardTitle: "Student Dashboard",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User List Response",
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "Student name ", key: "user_name", class: "text-left align-text-top pointer w-300px", sortable: true },
        { label: "Email", key: "user_email", class: "text-left align-text-top", sortable: true },
        { label: "Mobile", key: "user_mobile", class: "text-left align-text-top", sortable: true },
        { label: "Class", key: "class_name", class: "text-left align-text-top", sortable: true },
        { label: "Joined On", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      userObjList: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      blockObj: null,
      userCount: null,
      userProfileCompleted: null,
      Class: Class,
      cvClassFilter: "12",
      whereFilter: {
        user_role: "USERROLE11114",
        org_id: this.propOrgId,
        class_name: "12"
      },
      cvOrgType: "UNIV"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    this.cvOrgType = this.$route.params.org_type
    this.userListSetClassFilter()
    this.user_list_school_count()
    this.userListSchool()
  },
  methods: {
    /**
     * setUserRoleFilter
     */
    async setUserRoleFilter () {
      this.user_list_school_count()
      this.userListSchool()
    },
    /**
     * userListSetClassFilter
     */
    userListSetClassFilter () {
      this.whereFilter.class_name = this.cvClassFilter == "All" ? "" : this.cvClassFilter
      this.user_list_school_count()
      this.userListSchool()
    },
    /**
     * openGPathsReport
     */
    async openGPathsReport (url) {
      window.open(url, "_blank")
    },
    /**
     *
     * Redirect to User Profile
     */
    goToUserProfile (user) {
      try {
        let userId = user.user_id
        this.$router.push(`/user/${userId}`)
      }
      catch (err) {
        console.error("Exception occurred at UserList() and Exception:", err.message)
      }
    },
    /**
     * userListSchool
     */
    async userListSchool () {
      try {
        let userListResp = await User.userListSchool(this, this.whereFilter)
        if (userListResp.resp_status) {
          this.userObjList = userListResp.resp_data.data
          this.totalRows = userListResp.resp_data.count
        }
        else {
          this.userObjList = []
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    },
    /**
     * user_list_school_count
     */
    async user_list_school_count () {
      if (this.cvSchId) {
        this.whereFilter.org_id = this.cvSchId
      }
      try {
        let userAnalyticsList = await analyticsUser.user_list_school_count(this, this.whereFilter)
        if (userAnalyticsList.resp_status) {
          this.userCount = userAnalyticsList.resp_data.data[0].totalCount
          this.userProfileCompleted = userAnalyticsList.resp_data.data[0].totalProfileComplete
          this.paidCount = userAnalyticsList.resp_data.data[0].pa
        }
        else {
          this.toastMsg = userAnalyticsList.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at user_quick_profile() and Exception:", err.message)
      }
    }
  }
}
</script>
